<template>
    <div>
        <!-- CAROUSEL-->
    <!-- <b-row>
      <b-col cols="12" >
        <div>
          <b-carousel indicators :interval="3000" controls >
            <b-carousel-slide       
                v-if="resources!=null"         
                :img-src="resources.img_header_principal"
            />
          </b-carousel>
        </div>
      </b-col>
    </b-row>
     -->
        <br>

        <!-- <h1 class="titles">
          <span style="color: #d82125">M</span>
          <span style="color: #d02175">u</span>
          <span style="color: #f6c921">n</span>
          <span style="color: #d02175">i</span>
          <span style="color: #4d944a">c</span>
          <span style="color: #07aaba">i</span>
          <span style="color: #d82125">p</span>
          <span style="color: #d02175">i</span>
          <span style="color: #f6c921">o</span>
        </h1> -->
        
        <h1 class="titles">
          <span style="color: #8b8c8c">¿Dónde Hospedarse?</span>
        </h1>



        <br><br>

    <b-row>
        <b-col>
            <b-tabs content-class="mt-3" justified>

                <b-tab active>
                     <template #title>
                        <strong>Hoteles</strong>
                    </template>
                    <br>
                    <h2 class="titles">
                                <span style="color: #8b8c8c">Hoteles</span>
                    </h2>
                    <br>
                    <b-row  align-h="center"  v-for="(item, idx) in datos " :key="idx" >
                            <b-col cols="12" class="mt-4" v-if="item.tipo=='H'" ></b-col>
                            <b-col cols="12" sm="10" md="5" lg="3"  class="mb-3" v-if="item.tipo=='H'" >
                                <b-container  style="cursor:pointer;">
                                <b-img :src="'https://www.teotihuacan.gob.mx/turismot/1%20hoteles/'+item.img+'.jpg'" fluid rounded class="c-shadow-sm" ></b-img>
                                <b-img :src="'https://www.teotihuacan.gob.mx/turismot/1%20hoteles/'+item.img+'.jpeg'" fluid rounded class="c-shadow-sm" ></b-img>
                                </b-container>
                            </b-col>
                            <b-col cols="10" sm="8" md="5" lg="4" v-if="item.tipo=='H'">
                                <b-row><h5><b>{{item.nombre}}</b></h5></b-row>
                                <b-row><h6><b-icon icon="geo-fill"/> {{item.direccion}}</h6></b-row>
                                <b-row><h6><b-icon icon="telephone-fill"/> {{item.telefono}}</h6></b-row>
                                <b-row v-if="item.url!=null && item.url!='' && !item.url.includes('@')"><h6><b-icon icon="link"/><a :href="item.url" target="_blank"> Visitar Sitio</a></h6></b-row>
                            </b-col>
                            <b-col cols="12" class="mb-5" v-if="item.tipo=='H'" ></b-col>
                        
                    </b-row>
                </b-tab>

                <b-tab>
                     <template #title>
                        <strong>Posadas</strong>
                    </template>
                    <b-row  align-h="center"  v-for="(item, idx) in datos " :key="idx" >
                            <b-col cols="12" class="mt-4" v-if="item.tipo=='P'" ></b-col>
                            <b-col cols="12" sm="10" md="5" lg="3"  class="mb-3" v-if="item.tipo=='P'">
                                <b-container  style="cursor:pointer;">
                                <b-img :src="'https://www.teotihuacan.gob.mx/turismot/1%20hoteles/'+item.img+'.jpg'" fluid rounded class="c-shadow-sm" ></b-img>
                                <b-img :src="'https://www.teotihuacan.gob.mx/turismot/1%20hoteles/'+item.img+'.jpeg'" fluid rounded class="c-shadow-sm" ></b-img>
                                </b-container>
                            </b-col>
                            <b-col cols="10" sm="8" md="5" lg="4" v-if="item.tipo=='P'">
                                <b-row><h5><b>{{item.nombre}}</b></h5></b-row>
                                <b-row><h6><b-icon icon="geo-fill"/> {{item.direccion}}</h6></b-row>
                                <b-row><h6><b-icon icon="telephone-fill"/> {{item.telefono}}</h6></b-row>
                                <b-row v-if="item.url!=null && item.url!='' && !item.url.includes('@')"><h6><b-icon icon="link"/><a :href="item.url" target="_blank"> Visitar Sitio</a></h6></b-row>
                            </b-col>
                            <b-col cols="12" class="mb-5" v-if="item.tipo=='P'" ></b-col>
                    </b-row>
                </b-tab>

                <b-tab>
                     <template #title>
                        <strong>Moteles</strong>
                    </template>
                    <b-row  align-h="center"  v-for="(item, idx) in datos " :key="idx" >
                            <b-col cols="12" class="mt-4" v-if="item.tipo=='M'" ></b-col>
                            <b-col cols="12" sm="10" md="5" lg="3"  class="mb-3" v-if="item.tipo=='M'">
                                <b-container  style="cursor:pointer;">
                                <b-img :src="'https://www.teotihuacan.gob.mx/turismot/1%20hoteles/'+item.img+'.jpg'" fluid rounded class="c-shadow-sm" ></b-img>
                                <b-img :src="'https://www.teotihuacan.gob.mx/turismot/1%20hoteles/'+item.img+'.jpeg'" fluid rounded class="c-shadow-sm" ></b-img>
                                </b-container>
                            </b-col>
                            <b-col cols="10" sm="8" md="5" lg="4" v-if="item.tipo=='M'">
                                <b-row><h5><b>{{item.nombre}}</b></h5></b-row>
                                <b-row><h6><b-icon icon="geo-fill"/> {{item.direccion}}</h6></b-row>
                                <b-row><h6><b-icon icon="telephone-fill"/> {{item.telefono}}</h6></b-row>
                                <b-row v-if="item.url!=null && item.url!='' && !item.url.includes('@')"><h6><b-icon icon="link"/><a :href="item.url" target="_blank"> Visitar Sitio</a></h6></b-row>
                            </b-col>
                            <b-col cols="12" class="mb-5" v-if="item.tipo=='M'" ></b-col>
                    </b-row>
                </b-tab>

                 <b-tab >
                    <template #title>
                        <strong>Airbnb</strong>
                    </template>
                    <b-row  align-h="center"  v-for="(item, idx) in datos " :key="idx" >
                            <b-col cols="12" class="mt-4" v-if="item.tipo=='A'" ></b-col>
                            <b-col cols="12" sm="10" md="5" lg="3"  class="mb-3" v-if="item.tipo=='A'">
                                <b-container  style="cursor:pointer;">
                                <b-img :src="'https://www.teotihuacan.gob.mx/turismot/1%20hoteles/'+item.img+'.jpg'" fluid rounded class="c-shadow-sm" ></b-img>
                                <b-img :src="'https://www.teotihuacan.gob.mx/turismot/1%20hoteles/'+item.img+'.jpeg'" fluid rounded class="c-shadow-sm" ></b-img>
                                </b-container>
                            </b-col>
                            <b-col cols="10" sm="8" md="5" lg="4" v-if="item.tipo=='A'">
                                <b-row><h5><b>{{item.nombre}}</b></h5></b-row>
                                <b-row><h6><b-icon icon="geo-fill"/> {{item.direccion}}</h6></b-row>
                                <b-row><h6><b-icon icon="telephone-fill"/> {{item.telefono}}</h6></b-row>
                                <b-row v-if="item.url!=null && item.url!='' && !item.url.includes('@')"><h6><b-icon icon="link"/><a :href="item.url" target="_blank"> Visitar Sitio</a></h6></b-row>
                            </b-col>
                            <b-col cols="12" class="mb-5" v-if="item.tipo=='A'" ></b-col>
                    </b-row>
                </b-tab>


                <b-tab>
                     <template #title>
                        <strong>Trailer Park</strong>
                    </template>
                    <b-row  align-h="center"  v-for="(item, idx) in datos " :key="idx" >
                            <b-col cols="12" class="mt-4" v-if="item.tipo=='T'" ></b-col>
                            <b-col cols="12" sm="10" md="5" lg="3"  class="mb-3" v-if="item.tipo=='T'">
                                <b-container  style="cursor:pointer;">
                                <b-img :src="'https://www.teotihuacan.gob.mx/turismot/1%20hoteles/'+item.img+'.jpg'" fluid rounded class="c-shadow-sm" ></b-img>
                                <b-img :src="'https://www.teotihuacan.gob.mx/turismot/1%20hoteles/'+item.img+'.jpeg'" fluid rounded class="c-shadow-sm" ></b-img>
                                </b-container>
                            </b-col>
                            <b-col cols="10" sm="8" md="5" lg="4" v-if="item.tipo=='T'">
                                <b-row><h5><b>{{item.nombre}}</b></h5></b-row>
                                <b-row><h6><b-icon icon="geo-fill"/> {{item.direccion}}</h6></b-row>
                                <b-row><h6><b-icon icon="telephone-fill"/> {{item.telefono}}</h6></b-row>
                                <b-row v-if="item.url!=null && item.url!='' && !item.url.includes('@')"><h6><b-icon icon="link"/><a :href="item.url" target="_blank"> Visitar Sitio</a></h6></b-row>
                            </b-col>
                            <b-col cols="12" class="mb-5" v-if="item.tipo=='T'" ></b-col>
                    </b-row>
                </b-tab>

            </b-tabs>
        </b-col>
    </b-row>


    

    </div>
</template>
<script>
  export default {
    components: {
    },
    mounted: function(){
     
    },
    computed: {
      
    },
    data() {
      return {
        resources:null,
        datos:[
{img:'27',nombre:'Hotel Quinto Sol',direccion:'Avenida Hidalgo 26, Centro, 55800 San Juan Teotihuacán, Méx.',telefono:'  01 594 956 1881  5538534870',url:'https://www.hotelquintosol.mx/',tipo:'H'},
{img:'26',nombre:'Hotel & Temazcal Telpochcalli',direccion:'Av. Constitución #8 San Sebastián xolalpa Teotihuacán Estado de México',telefono:'5941016301  5535412784  5519127569',url:'https://nguide.ar-living360.mx/H-Ptelpochcalli.html',tipo:'H'},
{img:'24',nombre:'Hotel Villas Arqueológicas Teotihuacan & Spa',direccion:'Periférico sur s/n 55800 San Sebastián Xolalpa Teotihuacán Estado de México',telefono:'5949560244   5558369020',url:'http://www.villasteo.com/index',tipo:'H'},
{img:'62',nombre:' Hotel Cantera Jaguar ',direccion:'Francisco Sarabia 6, Teotihuacan Centro, 55800 Teotihuacán de Arista, Méx.',telefono:'5941192133',url:'https://m.facebook.com/people/Hotel-Cantera-Jaguar-Teotihuac%C3%A1n/100095180883387/',tipo:'H'},
{img:'21',nombre:' Hotel SPA Casa de la Luna ',direccion:'Calle Fresno #2 Cozotlan Norte Teotihuacán Estado de México',telefono:'5949563877  4424710438',url:'www.casadelaluna.com.mx',tipo:'H'},
{img:'29',nombre:'Hotel Hacienda Teoticamp',direccion:'Av., Ignacio Beteta 1A, 55843 San Juan Teotihuacán de Arista, Méx.',telefono:'594 956 2396',url:'https://es-la.facebook.com/teoticamp/',tipo:'H'},
{img:'20',nombre:' Hotel Calli Yolotl',direccion:'Calle nueva 2 Santa María Coatlan Teotihuacán Estado de México',telefono:'5941016314  5541350694',url:'https://www.facebook.com/CalliYolotl',tipo:'H'},
{img:'25',nombre:' Hotel Fer Teotihuacán',direccion:'C. La palma, int.2 San Francisco Mazapa Teotihuacán Estado de México',telefono:'5511944228   5632673338',url:'https://hotel-fer.negocio.site/?utm_source=gmb&utm_medium=referral',tipo:'H'},
{img:'32',nombre:' Hotel Villa Meztli',direccion:'Gustavo Baz MZ4 lote 3, Evangelista, 55815 San Juan Teotihuacán de Arista, Méx.',telefono:' 594 956 1582',url:'https://villa-meztli-san-juan-teotihuacan.booked.mx/',tipo:'H'},
{img:'16',nombre:' Hotel Boutique Acocalli Teotihuacán',direccion:'Reforma 8 col. Centro San Juan Teotihuacán Estado de México',telefono:'5941080828  Whatsapp:5566119675',url:'hotelacocalli@hotmail.com',tipo:'H'},
{img:'11',nombre:' Hotel villa las campanas',direccion:'calle constitución #43 San Sebastián Xolalpa Teotihuacán Estado de México ',telefono:'5516553951  5632625759',url:'www.villalascampanas@hotmail.com',tipo:'H'},
{img:'31',nombre:' Hotel TonallInn ',direccion:'Carretera pirámides #33 purificación Teotihuacán ',telefono:'5547066911',url:'https://www.hoteltonallinn.com/',tipo:'H'},



{img:'',nombre:'HOTEL',direccion:'',telefono:'',url:'',tipo:''},
{img:'22',nombre:'Hotel Ollin Teotl',direccion:'Atetelco #14 Purificación San juan Teotihuacán Estado de México ',telefono:'5949560390   5522572454',url:'Facebook@ollin1809   ',tipo:'H'},



{img:'12',nombre:' Hotel Kuswa',direccion:'Emiliano Zapata s/n San Juan Evangelista Teotihuacán Estado de México ',telefono:'5946882284  5554152068',url:'kuswa.hotel@gmail.com',tipo:'H'},
{img:'23',nombre:' Hotel Posada Sol y Luna',direccion:'Av. Dr. Jiménez Cantú #13 Purificación Teotihuacán Estado de México',telefono:'5949562368  5949562374',url:'http://hotelposadasolyluna.com.mx/        ',tipo:'H'},
{img:'17',nombre:' Hotel La Finca del Abuelo ',direccion:'Calle Juárez #10 Purificación San Juan Teotihuacán Estado de México ',telefono:'5949332264  WhatsApp: 5583494864',url:'https://hotel-la-finca-del-abuelo-teotihuacan.negocio.site/',tipo:'H'},
{img:'30',nombre:'CASA DE LA MORA',direccion:'Paseo solidaridad/plazuela S/N San Sebastián Xolalpa ',telefono:'5527479070',url:'',tipo:'H'},


{img:'15',nombre:' Hotel Vicalis & restaurante',direccion:'Circuito periférico s/n San Sebastián Xolalpa 55824 Teotihuacán Estado de México',telefono:'5941081423   5941081228  Whatsapp:5521051428',url:'https://m.facebook.com/VicalisHotel/',tipo:'H'},
{img:'35',nombre:' Hotel Teotihuacán ',direccion:' Pemex N.32, 55847 San Lorenzo Tlalmimilolpan, Méx',telefono:'594 956 8588',url:'https://www.hotelteotihuacan.com/',tipo:'H'},
{img:'19',nombre:' Hotel Boutique coatlicue',direccion:'Calle Teotihuacán #10 Santa María Coatlan Teotihuacán Estado de México',telefono:'5561654471   5572161918',url:'HOTEL COATLICUE (Facebook)  coatlicue.g.g@gmail.com',tipo:'H'},
{img:'28',nombre:' Hospedaje- Temazcal casa de barro',direccion:'Carretera México - Tulancingo Km.24 Teotihuacán Estado de México',telefono:'5524099954',url:'facebook/casadebarro',tipo:'H'},

{img:'13',nombre:' Cabañas Kallinantli',direccion:'Tetitla #5 Barrio de Purificación Teotihuacán Estado de México',telefono:'5527055486',url:' http:/instagram.com/kallinantli?r=nametag',tipo:'H'},
{img:'14',nombre:' Hotel Quetzalcalli Teotihuacán',direccion:'Calle constitución No.8-A San Sebastián Xolalpa Teotihuacán Estado de México ',telefono:'5941616037 551699501',url:'www.hotelquetzalcalli.com',tipo:'H'},
{img:'33',nombre:' Hotel Jardin Finca Ayotl Calli',direccion:'Melchor Ocampo #9 Teotihuacán centro ',telefono:'',url:'',tipo:'H'},
{img:'34',nombre:' Rancho San Juan ',direccion:'México Pirámides km 19, Pirámides, 55800 San Juan Teotihuacán de Arista, Méx',telefono:'55 3171 4090',url:'https://www.hoteles.com/ho1718049728/hotel-rancho-san-juan-teotihuacan-teotihuacan-mexico/?rffrid=sem.hcom.MX.google.003.00.10.PT.s.kwrd%3Dc.527203502338.123887416536.13476776327..aud-887569465082%3Adsa-1414880546282.9073884...CjwKCAjw9-KTBhBcEiwAr19ig1kyFhxKaWda-CauUMbOfMJ0CI3UjQhOzrZRlM3HnVwS1ty6nHKvbBoCjBoQAvD_BwE.aw.ds&PSRC=&gclid=CjwKCAjw9-KTBhBcEiwAr19ig1kyFhxKaWda-CauUMbOfMJ0CI3UjQhOzrZRlM3HnVwS1ty6nHKvbBoCjBoQAvD_BwE',tipo:'H'},
{img:'18',nombre:' Hotel Palmas Teotihuacán',direccion:'Continuación López Mateos #3 San Juan Teotihuacán col. Centro',telefono:'WhatsApp: 5612834194',url:'www.hotelpalmasteotihuacan.com   @hotelpalmasteotihuacan.com',tipo:'H'},

{img:'1',nombre:'airbnb Lofst Teotihuacán ',direccion:'Puxtla Teotihuacán ',telefono:'',url:'https://www.airbnb.mx/rooms/43309356?source_impression_id=p3_1651876036_voGhH3W%2FvJQJA4y1',tipo:'A'},
{img:'2',nombre:'Departamento Casa Alva Ixtlixochitl',direccion:'Pzla. de la Parroquia, Teotihuacán Centro, 55800 San Juan Teotihuacán de Arista, Méx',telefono:'55 1290 2771',url:'https://casas-alva-ixtlixochitl-san-juan-teotihuacan.booked.mx/',tipo:'A'},
{img:'3',nombre:'Albergue casa Alex Austria ',direccion:'16 de Septiembre, Teotihuacán Centro, 55800 San Juan Teotihuacán de Arista, Méx.',telefono:'',url:'',tipo:'A'},
{img:'4',nombre:' Alojamiento Escapate a Teotihuacán ',direccion:'Av. Pirámides #7 Teotihuacán centro',telefono:'5519376007',url:'',tipo:'A'},
{img:'5',nombre:'The driming house ',direccion:' C. Libertad 16, Centro, 55810 San Sebastián Xolalpa, Méx.',telefono:'594 956 3969',url:'https://es-la.facebook.com/dreaminghousemx/',tipo:'A'},
{img:'6',nombre:' Casa cazones ',direccion:'Calle girasol Tlajinga ',telefono:'',url:'',tipo:'A'},
{img:'7',nombre:' Yahualli Teotihuacán ',direccion:' Privada de Melchor Ocampo #7, Puxtla, San Juan Teotihuacán de Arista',telefono:'5949563255',url:'https://buendiatuxtepec.com.mx/company/yohualli-hostel-teotihuacan-698894/',tipo:'A'},
{img:'8',nombre:' Casa Roldan ',direccion:'Puxtla, Teotihuacán Centro, 55800 San Juan Teotihuacán de Arista, Méx.',telefono:' 55 1290 2771',url:'https://casa-roldan-villa-san-juan-teotihuacan.booked.mx/',tipo:'A'},
{img:'9',nombre:' Meson de la pineda ',direccion:'Cerrada Guerrero No 12, San Juan Teotihuacán 55816 México',telefono:'',url:'',tipo:'A'},
{img:'10',nombre:'Calli Tezcatlipoca',direccion:' Jorge Jiménez 7, Evangelista, 55816 San Juan Teotihuacán de Arista, Méx.',telefono:'',url:'',tipo:'A'},


{img:'',nombre:'MOTEL',direccion:'',telefono:'',url:'',tipo:''},
{img:'36',nombre:' Hotel posada el Encanto ',direccion:'De los potreros S/N Purificación Teotihuacán',telefono:'',url:'https://hotel-posada-el-encanto-san-juan-teotihuacan.booked.mx/',tipo:'M'},
{img:'37',nombre:' Hotel Posada Obsesión ',direccion:' C. Canteroco 12, Purificación, 55800 San Juan Teotihuacán de Arista, Méx.',telefono:' 594 956 2224',url:'https://www.allbiz.mx/hotel-posada-obsesi%C3%B3n_1M-594-956-2224',tipo:'M'},

{img:'',nombre:'POSADA',direccion:'',telefono:'',url:'',tipo:''},
{img:'41',nombre:'Hotel Posada Casa de las Mascaras ',direccion:'C.Morelos  19 San Sebastián Xolalpa Teotihuacán Estado de México',telefono:'594560588  5544896145  5564999039',url:'Casa de las Mascaras (Facebook) ',tipo:'P'},
{img:'42',nombre:' Posada los Ahuehuetes',direccion:'Adolfo López Mateos #16 San Juan Teotihuacán col. Centro',telefono:'5949561339  5584121666',url:'Restaurante Los Ahuehuetes (Facebook)',tipo:'P'},
{img:'46',nombre:' Restaurante y Hospedaje Los Adobes',direccion:'Canteroco #12 San Juan Teotihuacán col. Centro Estado de México',telefono:'5949561287  5534425026',url:'Facebook restaurante los adobes Teotihuaca  juanchilla@hotmail.com',tipo:'P'},
{img:'49',nombre:' Posada Colibrí ',direccion:'Pról., Av. Miguel Hidalgo 37, 55816 San Juan Teotihuacán Col. Centro',telefono:'5949332025',url:'https://posadacolibri.com',tipo:'P'},
{img:'40',nombre:' Sierra Patlachique ',direccion:'Morelos 02 San Sebastián Xolalpa Teotihuacán Estado de México ',telefono:'5531866106',url:'www.sierrapatlchique.com.mx',tipo:'P'},
{img:'53',nombre:' Posada Real ',direccion:'Av. Hombres ilustres 3, San Francisco Mazapa, 55820 San Juan Teotihuacán de Arista, Méx.',telefono:'55 5567 2520',url:'https://www.allbiz.mx/posada-real_9n-55-5567-2520',tipo:'P'},
{img:'44',nombre:'Estancia Ehécatl',direccion:'Calle Tlaxcala #8 San Sebastián Xolalpa Teotihuacán Estado de México',telefono:'5949562209   5513061800',url:'www.facebook.com/EstanciaEhécatl',tipo:'P'},
{img:'43',nombre:'Posada Yauhtli',direccion:'av. Ignacio Ramón Beteta 52 Maquixco Teotihuacán Estado de México',telefono:'5559092683',url:'         -',tipo:'P'},
{img:'47',nombre:' Posada El Sueño de Quetzalcóatl',direccion:'Calle Aztecas #1San Sebastián Xolalpa Teotihuacán Estado de México',telefono:'5941081309   WhatsApp: 5564202951',url:'elsuenodequetzalcoatl@gmail.com  elsuenodequetzalcoatl.com',tipo:'P'},
{img:'51',nombre:' Hotel Posada Sol Azteca ',direccion:'C. Canteroco 12, Centro, 55800 San Juan Teotihuacán de Arista, Méx.',telefono:' 998 219 9128',url:'https://hoteles-mx.com/hotel-posada-sol-azteca/',tipo:'P'},
{img:'52',nombre:' Hotel Posada San Francisco ',direccion:'Del sol #3 San Francisco Mazapa Teotihuacán ',telefono:'55 5073 4949',url:'',tipo:'P'},
{img:'50',nombre:' Iré Ile',direccion:'',telefono:'',url:'',tipo:'P'},
{img:'54',nombre:' Casa de Huespedes Chantli Quetzalli',direccion:'Acceso a Calzada de los Muertos por Prta 1 4, 55829 San Sebastián Xolalpa, Méx.',telefono:' 55 3407 4561',url:'https://planetofhotels.com/en/mexico/san-juan-teotihuacan/chantli-quetzalli',tipo:'P'},
{img:'57',nombre:' Posada Nantli',direccion:'Av. De los muertos #9 San Sebastián Xolalpa Teotihuacán ',telefono:'',url:'',tipo:'P'},
{img:'58',nombre:' Posada la dulce villa ',direccion:'Constitución 50 San Sebastián Xolalpa 55824 San Juan Teotihuacán de Arista, 55824 San Sebastián Xolalpa, Méx.',telefono:'55 6809 4328',url:'https://www.hoteles.com/ho1502911424/posada-la-dulce-villa-teotihuacan-mexico/?rffrid=sem.hcom.MX.google.003.00.10.PT.s.kwrd%3Dc.527203502338.123887416536.13476776327..aud-887569465082%3Adsa-1414880546282.9073884...CjwKCAjw9-KTBhBcEiwAr19ig5OSl2aSx67bHS7cnM_u8Eku7xTTm69yc_zOH0vWtgrnwfHVXMXUmxoCB-MQAvD_BwE.aw.ds&PSRC=&gclid=CjwKCAjw9-KTBhBcEiwAr19ig5OSl2aSx67bHS7cnM_u8Eku7xTTm69yc_zOH0vWtgrnwfHVXMXUmxoCB-MQAvD_BwE',tipo:'P'},
{img:'56',nombre:' Hotel posada Tonanzin',direccion:'Durazno 72, Militar, 55824 San Sebastián Xolalpa, Méx',telefono:'594 108 0194',url:'https://www.hoteles.com/ho1125187200/hotel-posada-tonantzin-teotihuacan-mexico/?rffrid=sem.hcom.MX.google.003.00.10.PT.s.kwrd%3Dc.527203502341.123887416536.13476776327..aud-887569465082%3Adsa-1414880546282.9073884...CjwKCAjw9-KTBhBcEiwAr19ig_8bW9APcyJbhnf9Q6tObceWfdxYfI1H2Dyw3aHrOXENBgIOQydcsxoCzHkQAvD_BwE.aw.ds&PSRC=&gclid=CjwKCAjw9-KTBhBcEiwAr19ig_8bW9APcyJbhnf9Q6tObceWfdxYfI1H2Dyw3aHrOXENBgIOQydcsxoCzHkQAvD_BwE',tipo:'P'},
{img:'38',nombre:'Posada & SPA Jade Teotihuacán ',direccion:'Calle Cuernavaca #1 San Sebastián Xolalpa Teotihuacán Estado de México ',telefono:'5941016112  WhatsApp: 5536084007',url:'https://www.facebook.com/hotelposadajadeteotihuacan',tipo:'P'},
{img:'48',nombre:' Posada Viveros',direccion:'Calle 16 de Septiembre Col. Centro San juan Teotihuacán Estado de México',telefono:'5540317020',url:'**',tipo:'P'},
{img:'55',nombre:' Posada la estación ',direccion:'Calle del ferrocarril #8 San Sebastián Xolalpa Teotihuacán ',telefono:'',url:'https://www.hoteles.com/ho663510/posada-la-estacion-teotihuacan-mexico/?rffrid=sem.hcom.MX.google.003.00.10.PT.s.kwrd%3Dc.527257832588.124008984912.13474368126..aud-887569465082%3Adsa-975336368342.9073884...CjwKCAjw9-KTBhBcEiwAr19igzvFGvNfHnWoNPgc0firoKsmfWsSsX4X9LUAgl6DuET0DO191q-aVxoCO70QAvD_BwE.aw.ds&PSRC=&gclid=CjwKCAjw9-KTBhBcEiwAr19igzvFGvNfHnWoNPgc0firoKsmfWsSsX4X9LUAgl6DuET0DO191q-aVxoCO70QAvD_BwE',tipo:'P'},
{img:'39',nombre:' Hotel-Posada Jatziri',direccion:'Durazno 12 esquina de los pinos, San Sebastián Xolalpa Teotihuacán Estado de México',telefono:'5941016282 566797424',url:'Hotel posada Jatziri Teotihuacán   (Facebook)',tipo:'P'},
{img:'45',nombre:' Posada- Hostal Casa Blanca ',direccion:'Callejón constitución #14 San Sebastián Xolalpa Teotihuacán Estado de México ',telefono:'5584532253',url:'',tipo:'P'},
{img:'59',nombre:'Hotel la estación de amelia ',direccion:'Calle Santa María #3 San Lorenzo Tlalmimilolpan Teotihuacán ',telefono:'594 956 1823',url:'https://la-estancia-de-amelia-san-juan-teotihuacan.booked.mx/',tipo:'P'},

{img:'',nombre:'TRAILER PARK ',direccion:'',telefono:'',url:'',tipo:''},
{img:'60',nombre:' Trailer Park Teotihuacán',direccion:' Adolfo López Mateos 17, Teotihuacán Centro, 55800 San Juan Teotihuacán de Arista, Méx.',telefono:' 594 956 0313',url:'https://www.facebook.com/pg/Camping-Teotihuacan-Trailer-Park-111693293506442/posts/',tipo:'T'},



        ]
      }
    }
    
  }
</script>